import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

class InstagramBlock {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;

        this.sliderEl = this.root.querySelector('[data-instagram-slider]');
        this.nextEl = this.root.querySelector('[data-instagram-slider-next]');
        this.prevEl = this.root.querySelector('[data-instagram-slider-prev]');

        this.swiper = null;

        return true;
    }

    init() {
        this.setSlider();
    }

    setSlider() {
        if (!this.sliderEl) return;

        this.swiper = new Swiper(this.sliderEl, {
            modules: [Navigation],
            slidesPerView: 'auto',
            spaceBetween: 24,
            breakpoints: {
                992: {
                    spaceBetween: 100,
                },
            },
            navigation: {
                prevEl: this.prevEl,
                nextEl: this.nextEl,
            },
        });
    }
}

const initInstagramBlocks = () => {
    const sections = document.querySelectorAll('[data-instagram]');
    if (!sections) return;

    sections.forEach(item => {
        new InstagramBlock(item);
    });
};

initInstagramBlocks();
